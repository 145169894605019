import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NewsCard from './NewsCard';

const TopStories = ({ selectedLanguage }) => {
  const [topStories, setTopStories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [popupMessage, setPopupMessage] = useState(null);  // State to hold the popup message

  // Fetch top stories when component mounts or language changes
  useEffect(() => {
    const fetchTopStories = async () => {
      setLoading(true);
      setError(null);
      try {
        // Use environment variable for dynamic API URL
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/news/top-stories/${selectedLanguage}`
        );
        setTopStories(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchTopStories();
  }, [selectedLanguage]); // Re-fetch when the language changes

  // Handle bookmark changes and show popup message
  const handleBookmark = (article, isBookmarked) => {
    const message = isBookmarked
      ? `"${article.title}" has been added to bookmarks.`
      : `"${article.title}" has been removed from bookmarks.`;
    setPopupMessage(message);

    // Clear the popup message after 3 seconds
    setTimeout(() => {
      setPopupMessage(null);
    }, 3000);
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Top Stories</h2>

      {loading && <p>Loading top stories...</p>}
      {error && <p className="text-red-500">Error: {error.message}</p>}

      {popupMessage && (
        <div className="fixed bottom-4 right-4 bg-blue-500 text-white p-2 rounded-lg shadow-lg">
          {popupMessage}
        </div>
      )}

      <div>
        {topStories.length === 0 ? (
          <p>No top stories available.</p>
        ) : (
          <div className="grid grid-cols-1 gap-4">
            {topStories.map((article) => (
              <NewsCard
                key={article._id}
                article={article}
                onBookmark={handleBookmark} // Pass handleBookmark to NewsCard
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default TopStories;
