import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaBookmark } from 'react-icons/fa'; // Import the bookmark icon
import logo from '../assets/logo.png';

const Navbar = ({ onCategoryChange, onLanguageChange, currentLanguage, activeCategory }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate(); // For navigation

  // Handle logo click: Reset to show all news
  const handleLogoClick = () => {
    onCategoryChange('All'); // Pass 'All' to fetch all news
    navigate('/'); // Redirect to homepage
    setIsSidebarOpen(false); // Close sidebar if open
  };

  // Handle category menu click
  const handleMenuClick = (category) => {
    onCategoryChange(category); // Update category in parent component
    navigate(`/news/${category}`); // Navigate to category-specific page
    setIsSidebarOpen(false); // Close the sidebar after selection
  };

  // Handle language change
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang); // Change language
    onLanguageChange(lang); // Pass new language to parent component
    setIsSidebarOpen(false); // Close sidebar if open
  };

  // Handle bookmark click
  const handleBookmarkClick = () => {
    navigate('/bookmarks'); // Navigate to bookmarks page
    setIsSidebarOpen(false); // Close the sidebar after selection
  };

  return (
    <header className="fixed top-0 left-0 right-0 bg-white shadow-lg z-50">
      <nav className="flex items-center justify-center p-4">
        <div className="absolute left-4 top-1/2 transform -translate-y-1/2">
          <button
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            className="text-xl md:text-3xl text-black"
          >
            ☰ <span className="text-sm md:text-xl">{t('menu')}</span>
          </button>
        </div>
        <div className="text-center">
          {/* Logo click to show all news */}
          <Link to="/" onClick={handleLogoClick}>
            <img src={logo} alt="Logo" className="h-10" />
          </Link>
        </div>
      </nav>

      {/* Sidebar for menu and language */}
      <div
        className={`fixed inset-y-0 left-0 bg-gray-800 text-white w-2/5 md:w-1/4 transition-transform transform ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        <button
          onClick={() => setIsSidebarOpen(false)}
          className="absolute top-4 right-4 text-xl md:text-2xl"
        >
          ×
        </button>

        {/* Bookmark Icon and Text (Only on Mobile) */}
        <div className="flex items-start justify-start ml-3 mt-4 md:hidden">
          <button onClick={handleBookmarkClick} className="flex items-center text-sm text-white">
            <FaBookmark className="mr-2" /> {/* Bookmark Icon */}
            {t('bookmarks')} {/* Bookmark Text */}
          </button>
        </div>

        {/* Language selection (English and Hindi) */}
        <div className="flex items-center justify-center mt-2 ">
          <button
            onClick={() => changeLanguage('en')}
            className={`text-sm px-4 py-2 border rounded ${
              currentLanguage === 'en' ? 'bg-gray-500 text-white' : 'border-gray-400 text-gray-200'
            }`}
          >
            English
          </button>
          <button
            onClick={() => changeLanguage('hi')}
            className={`text-sm px-4 py-2 border rounded ${
              currentLanguage === 'hi' ? 'bg-gray-500 text-white' : 'border-gray-400 text-gray-200'
            }`}
          >
            हिंदी
          </button>
        </div>

        <hr className="my-4 border-gray-600" />

        {/* Category List */}
        <ul className="flex flex-col items-start px-4 md:px-8 mt-4 space-y-2 md:space-y-4">
          {/* Dynamically map over categories */}
          {[
            'india',
            'technology',
            'politics',
            'startup',
            'business',
            'sports',
            'international',
            'fashion',
            'entertainment',
            'travel',
            'science',
            'healthcare'
          ].map((cat) => (
            <li key={cat}>
              <button
                onClick={() => handleMenuClick(cat)}
                className={`text-sm md:text-xl w-full text-left py-2 px-4 rounded ${
                  activeCategory === cat ? 'bg-gray-600 text-white' : 'hover:bg-gray-600 hover:text-white'
                }`}
              >
                {t(cat.charAt(0).toUpperCase() + cat.slice(1))} {/* Translated and capitalized */}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </header>
  );
};

export default Navbar;
