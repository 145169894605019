import React from 'react';
import { FaNewspaper, FaExclamationCircle, FaHeadset, FaBullhorn } from 'react-icons/fa';

const ContactUs = () => {
  return (
    <div className="container mx-auto p-6">
      {/* Centered Title with Underline */}
      <h1 className="text-center text-3xl font-bold text-gray-700 mb-4  decoration-gray-700">
        Contact Us
      </h1>

      {/* First Row: Editorial, Complaints, and Customer Service */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* Editorial Card */}
        <div className="flex flex-col items-center border border-lightblue-300 rounded-lg p-6 transition-shadow duration-300 hover:shadow-lg">
          <FaNewspaper className="text-5xl text-blue-500 mb-4" />
          <h2 className="text-xl font-semibold text-gray-800 mb-2">Editorial</h2>
          <p className="text-gray-700 text-center">
            Letters, feedback, and queries to the editor. Letters for publication should be sent to:
            <a
              href="mailto:buzzlynow03@gmail.com"
              className="text-blue-500 hover:underline ml-1"
            >
              buzzlynow03@gmail.com
            </a>
          </p>
        </div>

        {/* Complaints Card */}
        <div className="flex flex-col items-center border border-lightblue-300 rounded-lg p-6 transition-shadow duration-300 hover:shadow-lg">
          <FaExclamationCircle className="text-5xl text-blue-500 mb-4" />
          <h2 className="text-xl font-semibold text-gray-800 mb-2">Complaints</h2>
          <p className="text-gray-700 text-center">
            Complaints about specific editorial content: It is the policy of both buzzlynow.com and Buzzly to correct errors and handle complaints as soon as possible. We can be contacted as follows:
            <a
              href="mailto:buzzlynow03@gmail.com"
              className="text-blue-500 hover:underline ml-1"
            >
              buzzlynow03@gmail.com
            </a>
          </p>
        </div>

        {/* Customer Service Card */}
        <div className="flex flex-col items-center border border-lightblue-300 rounded-lg p-6 transition-shadow duration-300 hover:shadow-lg">
          <FaHeadset className="text-5xl text-blue-500 mb-4" />
          <h2 className="text-xl font-semibold text-gray-800 mb-2">Customer Service</h2>
          <p className="text-gray-700 text-center">
            If you have problems using the site or general questions about the buzzlynow.com network, contact our Customer Services Officer directly at:
            <a
              href="mailto:support@buzzlynow.com"
              className="text-blue-500 hover:underline ml-1"
            >
              support@buzzlynow.com
            </a>
          </p>
        </div>
      </div>

      {/* Last Row: Advertising Card */}
      <div className="flex justify-center mt-8">
        <div className="flex flex-col items-center border border-lightblue-300 rounded-lg p-6 transition-shadow duration-300 hover:shadow-lg w-full max-w-sm">
          <FaBullhorn className="text-5xl text-blue-500 mb-4" />
          <h2 className="text-xl font-semibold text-gray-800 mb-2">Advertising on Buzzlynow</h2>
          <p className="text-gray-700 text-center">
            Interested in advertising on buzzlynow.com? Reach out to our advertising team to learn more about our promotional packages and opportunities. Contact us at:
            <a
              href="mailto:buzzlynow03@gmail.com"
              className="text-blue-500 hover:underline ml-1"
            >
              buzzlynow03@gmail.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
