import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import NewsCard from './NewsCard'; // Import the NewsCard component

const NewsPage = ({ language, newArticle, onBookmark }) => {
  const { category } = useParams(); // Get category from URL
  const [news, setNews] = useState([]);
  const [displayedNews, setDisplayedNews] = useState([]); // Displayed articles
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [itemsToShow, setItemsToShow] = useState(10); // Number of articles to show initially

  const ARTICLES_PER_LOAD = 10; // Number of articles to load on each "Load More"

  // Fetch news data based on language and category
  const fetchData = useCallback(async () => {
    setLoading(true); // Reset loading state on fetch
    setError(null); // Reset error state on fetch

    try {
      const url = category
        ? `${process.env.REACT_APP_API_URL}/news/${language}/category/${category}`
        : `${process.env.REACT_APP_API_URL}/news/${language}`;

      const response = await fetch(url);
      const contentType = response.headers.get('content-type');

      if (!response.ok) {
        if (response.status === 404) {
          throw new Error('No news available for this category.');
        } else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      }

      if (contentType && contentType.includes('application/json')) {
        const data = await response.json();

        // Sort the news by date, with newest first
        const sortedData = data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setNews(sortedData);
        setDisplayedNews(sortedData.slice(0, ARTICLES_PER_LOAD)); // Display initial articles
      } else {
        throw new Error('Received non-JSON response');
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  }, [category, language]);

  // Prepend the new article when it's added
  useEffect(() => {
    if (newArticle) {
      setNews((prevNews) => [newArticle, ...prevNews]);
      setDisplayedNews((prevDisplayedNews) => [newArticle, ...prevDisplayedNews]);
    }
  }, [newArticle]);

  // Fetch data when component mounts or when category/language changes
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Load more articles when the button is clicked
  const loadMoreNews = () => {
    const newItemsToShow = itemsToShow + ARTICLES_PER_LOAD;
    setDisplayedNews(news.slice(0, newItemsToShow)); // Show more articles
    setItemsToShow(newItemsToShow); // Update the number of displayed articles
  };

  if (loading) return <p className="text-center">Loading...</p>;
  if (error) return <p className="text-center text-red-500">{error.message}</p>;

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">
        {category ? `${category.charAt(0).toUpperCase() + category.slice(1)} News` : 'All News'}
      </h1>
      <div className="space-y-4">
        {displayedNews.length > 0 ? (
          displayedNews.map((article) => (
            <NewsCard
              key={article._id} // Use the unique key for each article
              article={article}
              onBookmark={onBookmark}
            />
          ))
        ) : (
          <p className="text-center">No news available for this category.</p>
        )}
      </div>

      {/* Show Load More button if there are more articles to display */}
      {itemsToShow < news.length && (
        <div className="text-center mt-6">
         <button
  className="px-4 py-2 bg-blue-300 text-white rounded shadow-md hover:shadow-lg hover:bg-white hover:text-red-500 transition-all duration-300"
  onClick={loadMoreNews}
>
  Load More
</button>

        </div>
      )}
    </div>
  );
};

export default NewsPage;
