import React, { useEffect, useState } from 'react';
import NewsList from './NewsList';
import PropTypes from 'prop-types';

const BookmarkPage = ({ onBookmark }) => {
  const [bookmarkedArticles, setBookmarkedArticles] = useState([]);

  useEffect(() => {
    // Fetch bookmarked articles from localStorage when the component mounts
    const storedBookmarks = JSON.parse(localStorage.getItem('bookmarkedArticles')) || [];
    setBookmarkedArticles(storedBookmarks);
  }, []);

  const handleBookmarkUpdate = (article) => {
    // Update bookmark list when an article is bookmarked/unbookmarked
    const updatedBookmarks = JSON.parse(localStorage.getItem('bookmarkedArticles')) || [];
    setBookmarkedArticles(updatedBookmarks);
  };

  return (
    <div>
      <h1 className="text-2xl mb-4 text-center text-gray-600">Bookmarked Articles</h1>
      {bookmarkedArticles.length === 0 ? (
        <p className="text-center">No bookmarked articles.</p>
      ) : (
        <NewsList 
          articles={bookmarkedArticles} 
          onBookmark={(article) => {
            onBookmark(article);
            handleBookmarkUpdate(article);
          }} 
          selectedCategory={null} // Not filtering by category
        />
      )}
    </div>
  );
};

BookmarkPage.propTypes = {
  onBookmark: PropTypes.func.isRequired,
};

export default BookmarkPage;
