import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaBars, FaTimes, FaBookmark, FaCog, FaArrowLeft } from 'react-icons/fa'; // Import icons
import { FaFire, FaStar } from 'react-icons/fa'; // Add trending and top stories icons
import logo from '../assets/logo.png';
import { IoNewspaperSharp } from "react-icons/io5";
import axios from 'axios';
import TopStories from './TopStories'; // Import TopStories component

const MobileNavbar = ({ onCategoryChange, onLanguageChange, currentLanguage ,setNews,language}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to toggle the sidebar
  const [isSettingsOpen, setIsSettingsOpen] = useState(false); // State to toggle the settings menu
  const { t } = useTranslation();
  const navigate = useNavigate(); // For navigation
  const [loading, setLoading] = useState(false);
  const [topStories, setTopStories] = useState([]);
  const [showTopStories, setShowTopStories] = useState(false);
  const [error, setError] = useState(null);
  const [activeIcon, setActiveIcon] = useState(null); // State to track the active icon




  const handleIconClick = (iconName) => {
    setActiveIcon(iconName); // Update active icon on click
  };

  // Handle logo click: Reset to show all news
  const handleLogoClick = () => {
    onCategoryChange('All'); // Pass 'All' to fetch all news
    navigate('/'); // Redirect to homepage
    setIsMenuOpen(false); // Close sidebar if open
  };
  

  // Handle category click
  const handleCategoryClick = (category) => {
    onCategoryChange(category);
    navigate(`/news/${category}`);
    setIsMenuOpen(false); // Close the menu after clicking a category
  };

  // Handle language change
  const changeLanguage = (lang) => {
    onLanguageChange(lang);
    setIsMenuOpen(false); // Close menu after language change
  };

  // Handle Bookmark click
  const handleBookmarkClick = () => {
    navigate('/bookmarks'); // Navigate to the bookmarks page
    setIsMenuOpen(false); // Close the menu after clicking the bookmark
  };

  
  const handleTopStoriesClick = async () => {
    console.log("Fetching top stories...");
    setLoading(true);
    setError(null); // Reset error state
    try {
      const language = 'en'; // You can pass the language dynamically
      const apiUrl = `${process.env.REACT_APP_API_URL}/news/top-stories/${language}`; // Correct URL
      
      const response = await axios.get(apiUrl);
      
      console.log("Fetched top stories:", response.data);
      setTopStories(response.data); // Save the fetched data to state
      
      setShowTopStories(true); // Show the top stories view
      setIsMenuOpen(false); // Close the side menu
      navigate('/top-stories'); // Navigate to top-stories page
    } catch (error) {
      console.error("Error fetching top stories:", error);
      setError(error); // Set error state if fetching fails
    } finally {
      setLoading(false); // Reset loading state
    }
  };
  

  return (
    <header className="bg-white shadow-lg fixed top-0 left-0 right-0 z-50">
      {/* Navbar */}
      <nav className="flex items-center justify-center p-4 relative">
        <div>
          <Link to="/" onClick={handleLogoClick}>
            <img src={logo} alt="Logo" className="h-10" />
          </Link>
        </div>
        <div className="absolute left-4">
          <button onClick={() => setIsMenuOpen(true)} className="text-xl">
            <FaBars />
          </button>
        </div>
      </nav>

      {/* Sidebar */}
      <div
        className={`fixed inset-y-0 left-0 bg-white text-black w-full transition-transform transform ${
          isMenuOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        <div className="px-6 relative overflow-y-auto max-h-screen"> {/* Enable scrolling */}
          {/* Header with Back Icon, Options Text, and Close Icon */}
          <div className="flex items-center justify-between h-8 bg-white shadow-sm fixed top-0 left-0 right-0 z-50">
            {isSettingsOpen ? (
              <button onClick={() => setIsSettingsOpen(false)} className="text-xl   text-blue-400">
                <FaArrowLeft className='pl-1' />
              </button>
            ) : (
              <div className="" /> // Placeholder for the back icon
            )}
            <h2 className="text-lg font-semibold  pl-6 text-gray-700 flex-grow">
              {isSettingsOpen ? 'Options' : 'Categories and Topics'}
            </h2>

            <div className="flex items-center">
              {!isSettingsOpen && (
                <button onClick={() => setIsSettingsOpen(true)} className="text-xl text-blue-400 ">
                  <FaCog />
                </button>
              )}
              <button onClick={() => setIsMenuOpen(false)} className="text-xl text-blue-400 ml-2 mr-2">
                <FaTimes />
              </button>
            </div>
          </div>
          <hr className="my-6 w-8 pb-6 border-b-2 border-blue-600" />

          {/* Language Options */}
          {isSettingsOpen && (
            <div className="mt-6">
              <button
                onClick={() => changeLanguage('en')}
                className={`block mb-2 w-full text-left py-2 px-4 ${
                  currentLanguage === 'en' ? 'bg-gray-500 text-white' : 'text-black'
                }`}
              >
                English
              </button>
              <button
                onClick={() => changeLanguage('hi')}
                className={`block w-full text-left py-2 px-4 ${
                  currentLanguage === 'hi' ? 'bg-gray-500 text-white' : 'text-black'
                }`}
              >
                हिंदी
              </button>
            </div>
          )}

          {/* Categories List */}
          {!isSettingsOpen && (
            <div className="mt-4 space-y-4">
              <ul className="space-y-2">
                {/* "All News" and "Bookmark" sections */}

                {/* Horizontal Scrolling Section for Icons */}
                <div className="overflow-x-auto whitespace-nowrap mt-4">
      <div className="flex items-center justify-start space-x-6 p-2">
        <div
          className={`flex flex-col items-center cursor-pointer ${
            activeIcon === "topStories" ? "text-blue-600" : "text-blue-400"
          }`}
          onClick={() => {
            handleTopStoriesClick();  // Call your first function
            handleIconClick("topStories");  // Call your second function
          }}
        >
          <FaStar className="text-4xl" />
          <span className="text-base text-center mt-1">Top Stories</span>
        </div>

        <div
          className={`flex flex-col items-center cursor-pointer ${
            activeIcon === "allNews" ? "text-blue-600" : "text-blue-400"
          }`}
          onClick={() => {
            handleLogoClick();  // Call your first function
            handleIconClick("allNews");  // Call your second function
          }}
        >
          <IoNewspaperSharp className="text-4xl" />
          <span className="text-base text-center mt-1">All News</span>
        </div>

     

        <div
          className={`flex flex-col items-center cursor-pointer ${
            activeIcon === "bookmark" ? "text-blue-600" : "text-blue-400"
          }`}
          onClick={() => {
            handleBookmarkClick();  // Call your first function
            handleIconClick("bookmark");  // Call your second function
          }}
        >
          <FaBookmark className="text-4xl" />
          <span className="text-base text-center mt-1">Bookmark</span>
        </div>
      </div>
    </div>
                <hr className="my-4 border-gray-300" />

                {/* Categories */}
                 {/* Suggested Topics */}
          {!isSettingsOpen && (
            <div>
              <h2 className="text-lg font-semibold text-gray-800 mt-4">Suggested Topics</h2>
              <hr className="my-4 w-4 border-b-2 border-blue-600" /> {/* Blue horizontal line */}

              {/* Categories Grid */}
              <div className="grid grid-cols-3 gap-4"> {/* 3 columns grid */}
                {[
                  {
                    name: 'India',
                    imageUrl: 'https://static.inshorts.com/inshorts/images/v1/variants/webp/xxs/2021/06_jun/23_wed/img_1624460796166_669.webp',
                  },
                  {
                    name: 'Technology',
                    imageUrl: 'https://static.inshorts.com/inshorts/images/v1/variants/webp/xxs/2021/06_jun/23_wed/img_1624464616755_571.webp',
                  },
                  {
                    name: 'Politics',
                    imageUrl: 'https://static.inshorts.com/inshorts/images/v1/variants/webp/xxs/2021/06_jun/23_wed/img_1624464346448_194.webp',
                  },
                  {
                    name: 'Business',
                    imageUrl: 'https://static.inshorts.com/inshorts/images/v1/variants/webp/xxs/2021/06_jun/23_wed/img_1624461180685_722.webp',
                  },
                  {
                    name: 'Startup',
                    imageUrl: 'https://static.inshorts.com/inshorts/images/v1/variants/webp/xxs/2021/06_jun/23_wed/img_1624464736209_126.webp',
                  },
                  {
                    name: 'International',
                    imageUrl: 'https://static.inshorts.com/inshorts/images/v1/variants/webp/xxs/2021/06_jun/23_wed/img_1624465494460_113.webp',
                  },
                  {
                    name: 'Sports',
                    imageUrl: 'https://static.inshorts.com/inshorts/images/v1/variants/webp/xxs/2021/06_jun/23_wed/img_1624464513890_381.webp',
                  },
                  {
                    name: 'Entertainment',
                    imageUrl: 'https://static.inshorts.com/inshorts/images/v1/variants/webp/xxs/2021/06_jun/23_wed/img_1624464945012_439.webp',
                  },
                  {
                    name: 'Fashion',
                    imageUrl: 'https://static.inshorts.com/inshorts/images/v1/variants/webp/xxs/2021/06_jun/23_wed/img_1624474338098_484.webp',
                  },
                  {
                    name: 'Science',
                    imageUrl: 'https://static.inshorts.com/inshorts/images/v1/variants/webp/xxs/2021/06_jun/23_wed/img_1624473418833_711.webp',
                  },
                  {
                    name: 'Travel',
                    imageUrl: 'https://static.inshorts.com/inshorts/images/v1/variants/webp/xxs/2021/06_jun/23_wed/img_1624473623614_109.webp',
                  },
                  {
                    name: 'Healthcare',
                    imageUrl: 'https://static.inshorts.com/inshorts/images/v1/variants/webp/xxs/2020/12_dec/26_sat/img_1608994151293_594.webp',
                  },
               
                  // Add more categories as needed
                ].map((category) => (
                    <div
                    key={category.name}
                    className="relative h-40 bg-cover bg-center text-black cursor-pointer border-l border-r border-t border-blue-300 rounded-md"
                    style={{ backgroundImage: `url(${category.imageUrl})` }}
                    onClick={() => handleCategoryClick(category.name.toLowerCase())}
                  >
                    {/* Text properly positioned to bottom-left inside the box */}
                    <div className="absolute bottom-0 left-0 w-full p-2 bg-white bg-opacity-75">
                      {/* Smaller and non-bold text with responsiveness */}
                      <span className="text-sm md:text-xs sm:text-xs text-black break-words">
                        {category.name}
                      </span>
                    </div>
                  
                    {/* Thicker blue bottom border */}
                    <div className="absolute bottom-0 left-0 w-full border-b-4 border-blue-600 rounded-b-md" />
                  </div>
                  
                  
                  
                  
                  
                ))}
              </div>
            </div>
          )}
              </ul>
            </div>
          )}
        </div>
      </div>
    
    </header>
  );
};

export default MobileNavbar;
