import React, { useState, useEffect } from 'react';
import { FaBookmark, FaShareAlt } from 'react-icons/fa';

const NewsCard = ({ article, onBookmark }) => {
  const [isBookmarked, setIsBookmarked] = useState(false);

  useEffect(() => {
    // Check if the article is already bookmarked when the component mounts
    const bookmarkedArticles = JSON.parse(localStorage.getItem('bookmarkedArticles')) || [];
    const isAlreadyBookmarked = bookmarkedArticles.some(a => a._id === article._id);
    setIsBookmarked(isAlreadyBookmarked);
  }, [article]);

  const handleBookmark = () => {
    const bookmarkedArticles = JSON.parse(localStorage.getItem('bookmarkedArticles')) || [];

    if (isBookmarked) {
      // Remove bookmark
      const updatedBookmarks = bookmarkedArticles.filter(a => a._id !== article._id);
      localStorage.setItem('bookmarkedArticles', JSON.stringify(updatedBookmarks));
    } else {
      // Add bookmark
      bookmarkedArticles.push(article);
      localStorage.setItem('bookmarkedArticles', JSON.stringify(bookmarkedArticles));
    }

    setIsBookmarked(!isBookmarked);

    // Call the onBookmark function passed from the parent
    onBookmark(article, !isBookmarked);  // Pass the article and the new bookmark status
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: article.title,
        url: article.sourceUrl,
      })
      .then(() => console.log('Shared successfully!'))
      .catch(err => console.error('Error sharing:', err));
    } else {
      // Fallback for browsers that don't support the Web Share API
      navigator.clipboard.writeText(article.sourceUrl);
      alert('Link copied to clipboard!');
    }
  };

  return (
    <div className="relative flex flex-col md:flex-row border p-4 mb-4 mx-4 md:mx-auto max-w-4xl shadow-md cursor-pointer transition-transform transform group">
      <img
        src={article.imageUrl}
        alt={article.title}
        className="w-full md:w-1/3 h-auto object-cover mb-4 md:mb-0 md:mr-4 max-h-60"
      />
      <div className="flex-1 flex flex-col justify-between px-4 md:px-6">
        <div>
          <h2 className="text-xl mb-1">{article.title}</h2>
          <p className="text-gray-500 text-xs mb-4">{new Date(article.date).toLocaleDateString()}</p>
          <p className="text-gray-400 text-sm mb-4">{article.description}</p>
        </div>
        <div>
          <a
            href={article.sourceUrl}
            className="text-gray-500 text-sm"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read more at <span className="font-bold text-blue-300">{article.source}</span>
          </a>
        </div>
      </div>

      {/* Bookmark and Share Buttons */}
      <div className="absolute bottom-4 right-4 opacity-0 group-hover:opacity-100 transition-opacity sm:hidden flex space-x-2">
        <button
          onClick={handleBookmark}
          className="text-2xl"
        >
          <FaBookmark className={isBookmarked ? 'text-blue-500' : 'text-gray-400'} />
        </button>
        <button
          onClick={handleShare}
          className="text-2xl text-gray-400"
        >
          <FaShareAlt />
        </button>
      </div>
    </div>
  );
};

export default NewsCard;
