import React, { useEffect, useState } from 'react';
import NewsCard from './NewsCard';
import PropTypes from 'prop-types';

const NewsList = ({ articles, onBookmark, selectedCategory }) => {
  const [error, setError] = useState(null);
  const [news, setNews] = useState([]);

  const fetchNews = async () => {
    try {
      const url = `/api/news/en/category/${selectedCategory || 'All'}`; // Adjust the language as needed
      console.log('Fetching from:', url);
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setNews(data);
    } catch (error) {
      console.error('Error fetching news:', error);
      setError(error.message);
    }
  };

  useEffect(() => {
    if (articles) {
      setNews(articles); // Use provided articles if available (like in the BookmarkPage)
    } else {
      fetchNews(); // Fetch news if no articles provided
    }
  }, [articles, selectedCategory]);

  return (
    <div>
      {error && <p className="text-red-500">{error}</p>}
      {news.length === 0 ? (
        <p>No articles found.</p>
      ) : (
        news.map(article => (
          <NewsCard 
            key={article._id} 
            article={article} 
            onBookmark={onBookmark} 
          />
        ))
      )}
    </div>
  );
};

NewsList.propTypes = {
  articles: PropTypes.array, // Make this optional
  onBookmark: PropTypes.func.isRequired,
  selectedCategory: PropTypes.string,
};

export default NewsList;
