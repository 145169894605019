import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar'; // Desktop Navbar
import MobileNavbar from './components/MobileNavbar'; // Mobile Navbar
import NewsPage from './components/NewsPage';
import BookmarkPage from './components/BookmarkPage';
import TopStories from './components/TopStories';
import Footer from './components/Footer';
import ContactUs from './components/ContactUs';
import i18n from './i18n'; // Ensure i18n configuration is imported for language handling
import AboutUs from './components/AboutUs';

const App = () => {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [language, setLanguage] = useState('en');
  const [bookmarkedArticles, setBookmarkedArticles] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Detect mobile view

  // Detect screen resize to toggle between mobile and desktop view
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Handler to update the selected category
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  // Handler to update the language
  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang); // Change language in i18n
    setLanguage(lang); // Update local language state
  };

  // Handler to add or remove bookmarks
  const handleBookmark = (article) => {
    setBookmarkedArticles((prevBookmarks) => {
      const isBookmarked = prevBookmarks.some((item) => item._id === article._id);
      if (isBookmarked) {
        return prevBookmarks.filter((item) => item._id !== article._id); // Remove from bookmarks
      } else {
        return [...prevBookmarks, article]; // Add to bookmarks
      }
    });
  };

  return (
    <>
      {/* Render Navbar based on screen size */}
      {isMobile ? (
        <MobileNavbar 
          onCategoryChange={handleCategoryChange} 
          onLanguageChange={handleLanguageChange} 
          currentLanguage={language} 
        />
      ) : (
        <Navbar 
          onCategoryChange={handleCategoryChange} 
          onLanguageChange={handleLanguageChange} 
          currentLanguage={language} 
        />
      )}
      <div className="pt-20"> {/* Add padding to account for the height of the Navbar */}
        <Routes>
          <Route 
            path="/" 
            element={<NewsPage selectedCategory={selectedCategory} language={language} onBookmark={handleBookmark} />} 
          />
          <Route 
            path="/news/:category" 
            element={<NewsPage selectedCategory={selectedCategory} language={language} onBookmark={handleBookmark} />} 
          />
          <Route 
            path="/bookmarks" 
            element={<BookmarkPage bookmarkedArticles={bookmarkedArticles} onBookmark={handleBookmark} />} 
          />
          <Route path="/top-stories" element={<TopStories selectedLanguage={language} />} /> {/* Fixed line */}
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about-us" element={<AboutUs />} />

        </Routes>
      </div>
      <Footer />
    </>
  );
};

export default App;
