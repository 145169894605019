import React from 'react';
import { FaRegBuilding, FaUsers, FaBullseye, FaCheckCircle } from 'react-icons/fa'; // Icons

const AboutUs = () => {
  return (
    <div>
      {/* Header Section */}
      <section className="relative text-white py-16 px-6 flex justify-between items-start mt-[-7px] bg-blue-200">
        <div className="w-full lg:w-1/2">
          <h1 className="text-4xl text-black mb-4">ABOUT</h1>
          <p className="text-lg text-black">Everyone wants to know this, so read on and get to know us better.</p>
        </div>
      </section>

      {/* Section: Introduction */}
      <section className="bg-white py-10 px-6 md:px-12 lg:px-24">
        <div className="max-w-6xl mx-auto mb-12 p-6  shadow-lg rounded-lg text-left">
          <h2 className="text-3xl md:text-4xl font-bold mb-4 text-left">Who Are We?</h2>
          <p className="text-lg md:text-xl text-left">
            Welcome to <strong>Buzzlynow</strong>, where we aim to provide unbiased, in-depth news coverage across various sectors. We are a team of passionate journalists dedicated to informing the public with accurate and transparent reporting.
          </p>
        </div>

        {/* Row of Cards (Three-column layout) */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* Ownership Section */}
          <div className="p-6 bg-white shadow-lg rounded-lg hover:shadow-xl transition-shadow">
            <div className="flex justify-center mb-4">
              <FaRegBuilding className="text-4xl text-blue-400 hover:text-blue-600 transition-colors" />
            </div>
            <h2 className="text-2xl font-semibold text-center mb-4">Ownership</h2>
            <p className="text-lg text-center">
              <strong>Buzzlynow</strong> is independently owned by <strong>Eram Shaikh</strong>, committed to journalistic integrity and transparency.
            </p>
          </div>

          {/* Editorial Team Section */}
          <div className="p-6 bg-white shadow-lg rounded-lg hover:shadow-xl transition-shadow">
            <div className="flex justify-center mb-4">
              <FaUsers className="text-4xl text-blue-400 hover:text-blue-600 transition-colors" />
            </div>
            <h2 className="text-2xl font-semibold text-center mb-4">Our Editorial Team</h2>
            <p className="text-lg text-center">
              Our team is led by experienced journalists, delivering impactful stories across sectors such as politics, business, and technology.
            </p>
          </div>

          {/* Mission and Purpose Section */}
          <div className="p-6 bg-white shadow-lg rounded-lg hover:shadow-xl transition-shadow">
            <div className="flex justify-center mb-4">
              <FaBullseye className="text-4xl text-blue-400 hover:text-blue-600 transition-colors" />
            </div>
            <h2 className="text-2xl font-semibold text-center mb-4">Our Mission</h2>
            <p className="text-lg text-center">
              Our mission is to inform, engage, and empower our audience by providing credible and balanced news for an informed society.
            </p>
          </div>

          {/* Credibility Section */}
          <div className="p-6 bg-white shadow-lg rounded-lg hover:shadow-xl transition-shadow">
            <div className="flex justify-center mb-4">
              <FaCheckCircle className="text-4xl text-blue-400 hover:text-blue-600 transition-colors" />
            </div>
            <h2 className="text-2xl font-semibold text-center mb-4">Our Credibility</h2>
            <p className="text-lg text-center">
              We adhere to the highest ethical standards, ensuring that our reporting is fact-checked, transparent, and free from bias.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
